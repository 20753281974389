import React, { Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import routesData from "../../routes";
import { AuthContext } from "../../context/auth-context";
const MainRoutes = props => {
    const { isLoggedIn } = useContext(AuthContext);
    const [routes, setRoutes] = useState();
    const [defaultPath, setDefaultPath] = useState();
    const location = useLocation();

    useEffect(() => {
    }, [location]);

    useEffect(() => {
        if (isLoggedIn) {
            setRoutes(routesData.user);
            setDefaultPath(routesData.userDefault);
        } else {
            setRoutes(routesData.guest);
            setDefaultPath(routesData.guestDefault);
        }
    }, [isLoggedIn]);

    return (
        <Suspense>
            <Routes>
                {
                    routes?.map((route) => <Route key={route.name} path={route.path} element={<route.element />} />)
                }
                {defaultPath && <Route path="*" element={<Navigate to={defaultPath} />} />}
            </Routes>
        </Suspense>
    )
};

export default MainRoutes;
