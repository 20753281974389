import React, { useContext } from "react";
import { useEffect } from "react";
import { AuthContext } from "../../context/auth-context";
import Footer from "../footer";
import Header from "../header";
import toastr from "toastr";
import queryString from "query-string";

const MainLayout = props => {
  const { isLoggedIn, logout } = useContext(AuthContext);
  const { msg } = queryString.parse(location.search);

  useEffect(() => {
    if (msg) {
      toastr.warning(msg, "Błąd!", {
        containerId: 'toast-container-center',
        timeOut: 5000,
        positionClass: "",
        target: ".alert",
        preventDuplicates: true
        //progressBar: true
      });

      // toastr.clear()
    }
  }, [msg]);

  return (
    <>
      <Header isLoggedIn={isLoggedIn} logout={logout} />
      <div
        className="alert"
        style={{ position: "relative", width: "100%" }}
      >
        <div id="toast-container-center"></div>
      </div>
      {props.children}
      <Footer isLoggedIn={isLoggedIn} />
    </>
  );
};

export default MainLayout;
