import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util/helpers';

const initialState = null;
// {
    // banner:   {
    //     url: null,
    //     image: null
    //  },
    // notification_bar: null,
    // notification_bar_top: null
//}

const initSetting = (state, data) => {
    return updateObject(state, data);
};

const setSetting = (state, data) => {
    return updateObject(state, data);
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_SETTING: return initSetting(state, action);
        case actionTypes.SET_SETTING: return setSetting(state, action);
        default:
            return state;
    }
};

export default reducer;
