import * as actionTypes from './actionTypes';

export const initSetting = () => {
    return {
        type: actionTypes.INIT_SETTING
    }
}

export const setSetting = (data) => {
    return {
        type: actionTypes.SET_SETTING,
        ...data
    };
};