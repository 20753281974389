import React, { Suspense, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions/";
import logo from "../../assets/logotype.png";
import plusIcon from "../../assets/5plus.svg";
import HostingSidemenu from "../sidemenu/hosting";
import { menus, userMenus } from "../../menus";
import { plusTypes } from "../../views/plus/enums/types";
import { AuthContext } from "../../context/auth-context";

const Header = ({ isLoggedIn, logout, setting }) => {
  const [isSale, setIsSale] = useState(false);
  const location = useLocation();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (location.pathname === "/promocje1") {
      setIsSale(true);
    } else {
      setIsSale(false);
    }
  }, [location]);

  const showMenu = () => {
    var x = document.getElementById("navbar-main");
    var y = document.getElementById("nav-toggle");
    if (x.className === "nav navbar-main") {
      x.className += " responsive";
    } else {
      x.className = "nav navbar-main";
    }

    if (y.className === "nav-toggle") {
      y.className += " responsive";
    } else {
      y.className = "nav-toggle";
    }
  };

  const showFivePlusState = id => {
    const isActive = user?.fiveplus?.type == id;
    return (
      <li className={isActive ? "xplus-status-success" : "xplus-status-error"}>
        {plusTypes[id]}
      </li>
    );
  };

  return (
    <>
      {setting?.notification_bar_top && (
        <div className="belt-promo belt-top">
          <div className="wrap larger container-fluid">
            <div className="row middle-xs">
              <div className="col-xs-12">
                <p
                  dangerouslySetInnerHTML={{
                    __html: setting?.notification_bar_top
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoggedIn ? (
        <>
          <div className="header">
            <div className="wrap larger container-fluid">
              <div className="row middle-xs">
                <div className="col-xs-12 col-sm-2 center-xs start-sm">
                  <Link to="/" className="logo">
                    <img src={logo} className="img-responsive" alt="" />
                  </Link>
                </div>

                <div className="col-xs-12 col-sm-4 center-xs end-sm">
                  <form action="" className="flexbox">
                    <input
                      type="text"
                      className="input flex"
                      placeholder="Wpisz szukaną frazę..."
                    />
                    <button className="btn btn-primary">Szukaj</button>
                  </form>
                </div>

                <div className="col-xs-12 col-sm-6 end-xs end-sm">
                  <ul
                    className="nav navbar-main"
                    id="navbar-main"
                    itemScope
                    itemType="http://schema.org/SiteNavigationElement"
                  >
                    {userMenus.map(m => (
                      <li id={m.id} key={"header_" + m.id}>
                        <Link style={m.style || {}} to={m.to}>
                          {m.title}
                        </Link>
                        {m.children && (
                          <ul>
                            {m.children?.map((c, i) => (
                              <li key={"header_nested_" + c.id + "_" + i}>
                                <Link to={c.to}>{c.title}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                    <li>
                      <a
                        href="#"
                        style={{ textDecoration: "underline" }}
                        onClick={logout}
                      >
                        Wyloguj
                      </a>
                    </li>
                  </ul>

                  <div
                    className="nav-toggle"
                    id="nav-toggle"
                    onClick={showMenu}
                  >
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="qMenu">
            <ul>
              <li className="plus-tab qMenu1 pull-out">
                <div className="pull-out-tab d-flex align-items-center">
                  <Link to="/5plus">
                    <img src={plusIcon} />
                    <div className="d-block">
                      Program
                      <br />5 plus
                    </div>
                  </Link>
                  <div className="pull-out-content">
                    <ul className="xplus-status">
                      {showFivePlusState(1)}
                      {showFivePlusState(2)}
                      {showFivePlusState(3)}
                      {showFivePlusState(5)}
                      {showFivePlusState(4)}
                    </ul>
                  </div>
                </div>
              </li>
              <HostingSidemenu />
            </ul>
          </div>
        </>
      ) : (
        <div className="header">
          <div className="wrap larger container-fluid">
            <div className="row middle-xs">
              <div className="col-xs-12 col-sm-3 center-xs start-sm">
                <Link to="/" className="logo">
                  <img src={logo} className="img-responsive" alt="" />
                </Link>
              </div>

              <div className="col-xs-12 col-sm-6 center-xs center-sm">
                <ul
                  className="nav navbar-main"
                  id="navbar-main"
                  itemScope
                  itemType="http://schema.org/SiteNavigationElement"
                >
                  {menus.map(m => (
                    <li id={m.id} key={"header_" + m.id}>
                      <Link to={m.to}>{m.title}</Link>
                      {m.children && (
                        <ul>
                          {m.children?.map((c, i) => (
                            <li key={"header_nested_" + c.id + "_" + i}>
                              <Link to={c.to}>{c.title}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>

                <div className="nav-toggle" id="nav-toggle" onClick={showMenu}>
                  <div className="line1"></div>
                  <div className="line2"></div>
                  <div className="line3"></div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 center-xs end-sm">
                <Link to="zaloguj" className="btn btn-light">
                  Zaloguj
                </Link>
                <Link to="rejestruj" className="btn btn-primary">
                  Rejestruj
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {setting?.notification_bar && (
        <div className="belt-promo belt-middle">
          <div className="wrap larger container-fluid">
            <div className="row middle-xs">
              <div className="col-xs-12">
                <p
                  dangerouslySetInnerHTML={{
                    __html: setting?.notification_bar
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    setting: state.setting
  };
};

export default connect(mapStateToProps)(Header);
