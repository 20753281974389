import React, { Suspense, useContext } from "react";
import { connect } from "react-redux";
import altLogo from "../../assets/logotype-alt.png";
import tpay from "../../assets/tpay.png";
import simpay from "../../assets/simpay.png";
import { Link } from "react-router-dom";

const Footer = ({ isLoggedIn, settings }) => {
  const { col1, col2, col3 } = settings?.footer || {};

  const footerLink = _link => {
    const { name, link } = _link;
    // Przekierowanie wewnętrzne
    if (link.startsWith("/"))
      return (
        <li>
          <Link to={link}>{name}</Link>
        </li>
      );
    else
      return (
        <li>
          <a target="_blank" href={link}>
            {name}
          </a>
        </li>
      );
  };

  return (
    <>
      <div id="footer">
        <div className="wrap container-fluid">
          <div className="row">
            <div className="col-xs-12 col-lg-2">
              <Link to="/" className="logo">
                <img src={altLogo} className="img-responsive" alt="" />
              </Link>
            </div>
            <div className="col-xs-12 col-lg-2">
              <a href="/cennik">
                <img
                  src={tpay}
                  className="img-responsive"
                  alt=""
                  style={{ maxWidth: "123px" }}
                />
              </a>
              {/* <a href="/cennik">
                <img
                  src={simpay}
                  className="img-responsive"
                  alt=""
                  style={{ maxWidth: "70px", marginTop: "12px" }}
                />
              </a> */}
            </div>
            <div className="col-xs-12 col-lg-2">
              <h5>{col1?.name}</h5>
              <ul>{col1?.links.map(link => footerLink(link))}</ul>
            </div>
            <div className="col-xs-12 col-lg-2">
              <h5>{col2?.name}</h5>
              <ul>{col2?.links.map(link => footerLink(link))}</ul>
            </div>
            <div className="col-xs-12 col-lg-2">
              <h5>{col3?.name}</h5>
              <ul>{col3?.links.map(link => footerLink(link))}</ul>
            </div>
            {!isLoggedIn && (
              <div className="col-xs-12 col-lg-2 end-lg">
                <Link
                  to="rejestruj"
                  className="btn btn-primary"
                  style={{ display: " inline-block", width: "fit-content" }}
                >
                  Rejestruj
                </Link>
                <br />
                <Link
                  to="zaloguj"
                  className="btn btn-light block"
                  style={{ display: "inline-block", width: "fit-content" }}
                >
                  Zaloguj
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="wrap container-fluid">
          <div className="row">
            <div className="col-xs-12 col-md-9 center-xs start-md">
              <p>
                Korzystanie z serwisu oznacza akceptację{" "}
                <a href="/regulamin">regulaminu</a> w aktualnym brzmieniu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    settings: state.setting
  };
};

export default connect(mapStateToProps)(Footer);
