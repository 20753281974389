import { Children } from "react";

const menus = [
  { id: "5plus", to: "5plus", title: "5 Plus" },
  { id: "prices", to: "cennik", title: "Cennik" },
  { id: "statute", to: "regulamin", title: "Regulamin" },
  {
    id: "help",
    to: "pomoc",
    title: "Pomoc",
    children: [
      {
        id: "statusy-hostingow",
        to: "statusy-hostingow",
        title: "Statusy hostingów"
      },
      { id: "coming-soon", to: "coming-soon", title: "Przerwa techniczna" },
      { id: "promocje1", to: "promocje1", title: "Promocje" },
      { id: "promocje2", to: "cennik?promocja=1", title: "Promocje - cennik" },
      { id: "komunikaty", to: "komunikaty", title: "Komunikaty" }
    ]
  },
  { id: "kontakt", to: "kontakt", title: "Kontakt" }
];

const userMenus = [
  {
    id: "dashboard",
    to: "dashboard",
    title: "Dashboard"
  },
  { id: "5plus", to: "5plus", title: "5 Plus" },
  { id: "prices", to: "cennik", title: "Cennik" },
  { id: "statute", to: "regulamin", title: "Regulamin" },
  { id: "help", to: "pomoc", title: "Pomoc" },
  { id: "kontakt", to: "kontakt", title: "Kontakt" }
];

export { menus, userMenus };
