import { lazy } from "react";
const Home = lazy(() => import("./views/home/"));

const Plus = lazy(() => import("./views/plus/"));
const Price = lazy(() => import("./views/prices/"));
const Statute = lazy(() => import("./views/statute/"));
const Help = lazy(() => import("./views/help/"));
const Contact = lazy(() => import("./views/contact/"));

const Login = lazy(() => import("./views/auth/login/"));
const Register = lazy(() => import("./views/auth/register/"));
const Activation = lazy(() => import("./views/auth/register/activation/"));
const Reminder = lazy(() => import("./views/auth/reminder/"));
const Logout = lazy(() => import("./views/auth/logout/"));

const Status = lazy(() => import("./views/hosting/status/"));
const Soon = lazy(() => import("./views/soon/"));
const Sale = lazy(() => import("./views/sale"));
const SalePrices = lazy(() => import("./views/salePrices"));

const Dashboard = lazy(() => import("./views/dashboard/"));
const Settings = lazy(() => import("./views/settings/"));
const Search = lazy(() => import("./views/search/"));
const Recharge = lazy(() => import("./views/recharge"));
const DownloadHistory = lazy(() => import("./views/history/dowload"));
const RechargeHistory = lazy(() => import("./views/history/recharge"));
const Report = lazy(() => import("./views/report"));
const Alerts = lazy(() => import("./views/alerts"));

const guestDefault = "/";

export const loginPath = "/zaloguj";

const guest = [
  { exact: true, path: "/komunikaty", name: "komunikaty", element: Alerts },

  { exact: true, path: "/", name: "Home", element: Home },

  { exact: true, path: "/5plus", name: "5plus", element: Plus },
  { exact: true, path: "/cennik", name: "cennik", element: Price },
  { exact: true, path: "/regulamin", name: "regulamin", element: Statute },

  { exact: true, path: "/pomoc", name: "pomoc", element: Help },
  { exact: true, path: "/pomoc/:cat", name: "pomoc-temat", element: Help },

  { exact: true, path: "/kontakt", name: "kontakt", element: Contact },
  {
    exact: true,
    path: "/kontakt/:category",
    name: "kontakt-temat",
    element: Contact
  },

  { exact: true, path: "/promocje1", name: "promocje1", element: Sale },
  { exact: true, path: "/promocje2", name: "promocje2", element: SalePrices },

  { exact: true, path: "/zaloguj", name: "zaloguj", element: Login },
  { exact: true, path: "/rejestruj", name: "rejestruj", element: Register },
  {
    exact: true,
    path: "/rejestruj/aktywacja/:token",
    name: "rejestruj-aktywacja",
    element: Activation
  },
  {
    exact: true,
    path: "/odzyskiwanie-hasla",
    name: "odzyskiwanie-hasla",
    element: Reminder
  },
  {
    exact: true,
    path: "/statusy-hostingow",
    name: "statusy-hostingow",
    element: Status
  },
  { exact: true, path: "/coming-soon", name: "coming-soon", element: Soon }
];

const userDefault = "/dashboard";

const user = [
  { exact: true, path: "/5plus", name: "5plus", element: Plus },
  { exact: true, path: "/cennik", name: "cennik", element: Price },
  { exact: true, path: "/regulamin", name: "regulamin", element: Statute },

  { exact: true, path: "/pomoc", name: "pomoc", element: Help },
  { exact: true, path: "/pomoc/:cat", name: "pomoc-temat", element: Help },

  { exact: true, path: "/kontakt", name: "kontakt", element: Contact },
  {
    exact: true,
    path: "/kontakt/:category",
    name: "kontakt-temat",
    element: Contact
  },

  { exact: true, path: "/wyloguj", name: "wyloguj", element: Logout },
  { exact: true, path: "/dashboard", name: "dashboard", element: Dashboard },
  { exact: true, path: "/ustawienia", name: "ustawienia", element: Settings },
  { exact: true, path: "/wyszukiwarka", name: "wyszukiwarka", element: Search },
  {
    exact: true,
    path: "/historia-pobran",
    name: "historia-pobran",
    element: DownloadHistory
  },
  {
    exact: true,
    path: "/historia-zasilen",
    name: "historia-zasilen",
    element: RechargeHistory
  },
  {
    exact: true,
    path: "/twoje-zgloszenia",
    name: "twoje-zgloszenia",
    element: Report
  },
  {
    exact: true,
    path: "/statusy-hostingow",
    name: "statusy-hostingow",
    element: Status
  },
  { 
     exact: true, path: "user/:action/:token",
     name: "user-actions", 
     element: Settings 
   }
];

export default { guest, guestDefault, user, userDefault };
