import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { stripTrailingSlash } from "../../util/helpers";
import axios from "axios";

export function* initSettingSaga(action) {
  try {
    const apiUrl = stripTrailingSlash(process.env.REACT_APP_API_URL);
    const response = yield axios.get(apiUrl + "/status");
    yield put(actions.setSetting(response.data));
  } catch (error) {}
}
