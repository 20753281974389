import Layout from "./layout";
import { AuthContext } from "./context/auth-context";
import { useAuth } from "./hooks/auth-hook";
import createSagaMiddleware from "redux-saga";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./store/reducers/rootReducer";
import CookieConsent from "react-cookie-consent";

import "./slick-theme.css";
import "./slick.css";
import "./flexboxgrid.min.css";
import "./flexboxgrid-helpers.min.css";
import "./App.css";

import { watchSetting } from "./store/sagas/";

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(watchSetting);

function App() {
  const { token, login, updateUser, logout, user } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!(token || JSON.parse(localStorage.getItem("userData"))),
        token: token,
        user: user,
        updateUser: updateUser,
        login: login,
        logout: logout
      }}
    >
      <CookieConsent
        location="bottom"
        buttonText="OK"
        buttonWrapperClasses="m-auto"
        buttonClasses="cookie__btn"
        containerClasses="cookie reset-margin-sm"
        contentClasses="cookie__content"
      >
        <div className="cookie__content__text">
          Ta strona korzysta z plików cookies, w celu realizacji usług. Jeśli
          nie wyrażasz zgody na wykorzystywanie plików cookies, prosimy o
          wyłącznie cookies w przeglądarce lub opuszczenie serwisu.
        </div>
      </CookieConsent>
      <Provider store={store}>
        <div className="App">
          <Layout />
        </div>
      </Provider>
    </AuthContext.Provider>
  );
}

export default App;
