import React, { useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route, location } from "react-router-dom";
import MainLayout from "./mainLayout";
import MainRoutes from "../containers/routes/mainRoutes";

import { connect } from 'react-redux';
import * as actions from '../store/actions/';

const Layout = ({ setting, onInitSetting }) => {
  const renders = useRef(1);

  useEffect(() => {
    if (renders.current === 1 && !setting) onInitSetting();
    renders.current++;
  });


  return (
    <BrowserRouter>
      <MainLayout>
        <MainRoutes />
      </MainLayout>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    setting: state.setting
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onInitSetting: () => dispatch(actions.initSetting())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
