import { useState, useCallback, useRef, useEffect, useContext } from "react";
import { AuthContext } from "../context/auth-context";
import qs from "query-string";
import { stripTrailingSlash } from "../util/helpers";
import toastr from "toastr";

const apiUrl = stripTrailingSlash(process.env.REACT_APP_API_URL);

export const useHttpClient = () => {
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (
      url,
      method = "GET",
      body = null,
      params = null,
      headers = {},
      isFormData = false
    ) => {
      const root = document.getElementById("root");
      root.style.cursor = "wait";
      setIsLoading(true);

      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      let query = qs.stringify(params || {}, { arrayFormat: "bracket" });

      var responseData;
      try {
        const token =
          auth.token || JSON.parse(localStorage.getItem("userData"))?.token;

        var path = url.startsWith("/") ? url : "/" + url;
        const response = await fetch(apiUrl + path + "?" + query, {
          method,
          headers: {
            Accept: "application/json",
            ...(!isFormData && {
              "Content-Type": "application/json"
            }),
            Authorization: "Bearer " + token,
            ...headers
          },
          body:
            body &&
            (isFormData
              ? body
              : typeof body !== "string"
              ? JSON.stringify(body)
              : body),
          signal: httpAbortCtrl.signal
        });

        responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          reqCrl => reqCrl !== httpAbortCtrl
        );

        if (responseData?.status === "warning") {
          setIsLoading(false);
          root.style.cursor = "";
          return responseData;
        }

        if (!response.ok) {
          if (responseData?.message === "Unauthenticated.") {
            auth.logout();
            return;
          }

          root.style.cursor = "";
          setError(responseData.errors);
          throw new Error(responseData.message);
        }

        setIsLoading(false);
        root.style.cursor = "";
        return responseData;
      } catch (err) {
        console.log("sendRequest", err);
        console.log("sendRequest", err.message);
        if (err.message === "Access denied") {
          auth.setAccess(false);
        } else {
          if (!err.message.includes("aborted"))
            toastr.warning(err.message, "Błąd!", {
              timeOut: 25000
              // positionClass: "tostr_absolute toastr_top_center"
            });
            setIsLoading(false);
            return;
        }

        if (err.message === "Unauthenticated.") {
          auth.logout();
        }

        setIsLoading(false);
        root.style.cursor = "";
        // throw err;

        return responseData;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
